import moment from 'moment';

export function getDateStringFromTimestamp(timestamp, pattern = 'DD.MM.YYYY HH:mm') {
  if (timestamp === null) {
    return '';
  } else {
    return moment(new Date(timestamp.seconds * 1000)).format(pattern);
  }
}

export function getDateStringFromDate(date = new Date(), pattern = 'YYYYMMDD_HHmmss') {
  return moment(date).format(pattern);
}

export function isFutureTimestamp(timestamp) {
  if (timestamp === null) {
    return false;
  } else {
    return timestamp.seconds * 1000 > Date.now();
  }
}

export function getDecimalFormat(value, forceFloat = false) {
	const options = {
		minimumFractionDigits: forceFloat ? 2 : 0,
		maximumFractionDigits: forceFloat ? 2 : 0
	};
	return Number(value).toLocaleString('de-DE', options);
}

export function getPriceFormat(value, forceInt = false) {
  const options = {
    minimumFractionDigits: forceInt ? 0 : 2,
		maximumFractionDigits: forceInt ? 0 : 2,
    style: 'currency',
    currency: 'EUR'
  }
	return Number(value / 100).toLocaleString('de-DE', options);
}

export function getUserEmail(email) {
  const FACEBOOK_SUFFIX = '@social.without.email';
  const index = email.indexOf(FACEBOOK_SUFFIX);
  return index > 0 ? `From Social: ${email.substring(0, index)}` : email;
}

export function toFileName(value) {
  return value.trim().replaceAll(/[^\w\s]/gi, '').replaceAll(/\s\s+/g, ' ').replaceAll(/\s/g, '-');
}

export function zeroPad(num, places = 3) {
  return String(num).padStart(places, '0');
}

export function getEventParam(eventInfo) {
  if (eventInfo && eventInfo.name && eventInfo.invoiceNumber) {
    return eventInfo.name.trim().replaceAll(/[^\w\s]/gi, '').replaceAll(/\s\s+/g, ' ').replaceAll(/\s/g, '-') + '-' + eventInfo.invoiceNumber;
  } else {
    return '';
  }
}

export function getEventLink(eventInfo) {
  const eventParam = getEventParam(eventInfo);
  if (eventParam) {
    return `https://www.eventboxde.com/e/${eventParam}`;
  } else {
    return 'https://www.eventboxde.com';
  }
}

// loader: spinner, dots, bars
export function showLoading(self, type = 0) {
  const loader = self.$loading.show({
    loader: type === 1 ? 'dots' : (type === 2 ? 'bars' : 'spinner'),
    backgroundColor: '#636F83',
    color: '#EB4C60'
  });
  return loader;
}

export function monthsSinceJan2023(date) {
  const targetYear = 2023;
  const targetMonth = 1; // January is now 1-indexed

  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1; // Add 1 to make months 1-indexed

  let totalMonths = (currentYear - targetYear) * 12 + (currentMonth - targetMonth);


    // Ensure the result is formatted as "0000" and doesn't go negative.
    let formattedMonths = String(Math.max(0, totalMonths + 1 )).padStart(3, '0'); // Pad with leading zeros. The +1 adjustment is now inside the max function



  return formattedMonths;
}